import React from "react";

import { TextField } from "@material-ui/core";
import { Button, Row, Col, Container, Card } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import { Formik, Field, Form } from "formik";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import DetailItem from "./DetailItem";
import { escapeObject } from "@iso/helpers/index";

import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper";
import requestStepActions from "@iso/actions/RequestStepActions";

import { giftMessageValidator } from "../validators";
import "./GiftMessage.scss";
const GiftMessage = ({ handleChange, touched, requestErrors, className, formikValues, setFormikValues }) => {
  const requestStepData = useSelector(
    (state) => state.requestStepReducer,
    shallowEqual
  );
  const [values, setValues] = React.useState(formikValues);
  const [edit, setEdit] = React.useState(false);
  const [isAutoSaved, setIsAutoSaved] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  const dispatch = useDispatch();

  React.useEffect(() => {
    setValues(escapeObject({
      ...values,
      recipient_name: formikValues.recipient_name,
    }, false))
  }, [formikValues.recipient_name])

  const handleEdit = (isAuto = false) => {
    if (edit) {
      try {
        giftMessageValidator.validate(values).then(
          () => {
            if (!isAuto) {
              setEdit(false);
            }
            setErrors({});
            let data = {
              ...formikValues,
              gift_message: values.gift_message,
              recipient_name: values.recipient_name,
              gift_message_name: values.gift_message_name
            };
            setFormikValues(data);
            dispatch(requestStepActions.setDataStep(data));
          },
          (error) => {
            setErrors({ [error.path]: error.message });
          }
        );
      } catch (error) { }
    } else {
      setIsAutoSaved(false);
      setEdit(true);
    }
  };
  const autoSaveTimeout = React.useRef(null);
  React.useEffect(() => {
    if(isChangeValue()){
      setIsAutoSaved(true);
      clearTimeout(autoSaveTimeout.current);
      autoSaveTimeout.current = setTimeout(() => { handleEdit(true) }, 1000)
    }
  }, [values])

  const isChangeValue = () => {
    return (
      values.gift_message !== formikValues.gift_message ||
      values.recipient_name !== formikValues.recipient_name ||
      values.gift_message_name !== formikValues.gift_message_name
    );
  }

  const bg = edit ? "" : "bg_light_blue";
  const removeBorderClass = edit ? "remove_border" : "";
  console.log("values:::", values)
  return (
    <Container className={` GiftMessage gift_message_wrapper ${removeBorderClass && " margin_bottom_gift_message"} ${className} `}>
      <Row>
        <Col sm="12">
          <Card className={`card_wrapper ${bg} ${edit ? 'border-none' : 'border bg-giftMsg'}`}>
            <Card.Body className="custom_padding_card_wrapper_gift_message">
              <Card.Title className="custom_card_title ">
                <Row>
                  <Col xs="8" className="padding_gift_title_message">
                    <IntlMessage id="public.request.step3.left.gift_message.title" />
                  </Col>
                  <Col
                    xs="4"
                    className="text-right text-hippie-blue hover_pointer padding_right_handle_click"
                  >
                    <b onClick={(e) => { handleEdit() }}>
                      {edit ? (
                        isAutoSaved ? (
                          isChangeValue() ? (<span style={{ color: "#cd5651" }}><IntlMessage id="common.auto_saved" /></span>) : <IntlMessage id="common.auto_saved" />
                        ) : (
                          isChangeValue() ? (<span style={{ color: "#cd5651" }}><IntlMessage id="common.save" /></span>) : <IntlMessage id="common.save" />
                        )
                      ) : (
                        <IntlMessage id="common.edit" />
                      )}
                    </b>
                  </Col>
                </Row>
              </Card.Title>
              <>
                {!edit ? (
                  <>
                    <DetailItem
                      className="custom_label_gift_message_is_not_gift custom_content_gift_message_is_not_gift"
                      label="public.request.step3.left.gift_message.description"
                      displayValue={
                        <>
                          <div>Dear {values.gift_message_name}&#x2C;</div>
                          <div>{values.gift_message}</div>
                        </>
                      }
                      i18n={false}
                    />
                    <ErrorMessageWrapper
                      touched={{ gift_message: true }}
                      errors={requestErrors}
                      fieldName="gift_message"
                    />
                  </>
                ) : (
                  <>
                    <TextField
                      id="gift_message_name"
                      label={
                        <IntlMessage
                          id={`public.request.step3.left.gift_message.description`}
                        />
                      }
                      value={values.gift_message_name}
                      autoFocus
                      className="bg_lighter_hippie_blue custom_label_text_input custom_title_gift_message custom_gift_message_textarea hidden-underline pl-1"
                      onChange={(e) =>
                        setValues({
                          ...values,
                          gift_message_name: e.target.value,
                        })
                      }
                      InputProps={{
                        startAdornment: <div className="text-dear pl-1 pl-lg-4 ml-1"><div style={{ height: "24px", lineHeight: "24px" }}>Dear </div></div>
                      }}
                      multiline
                    />
                    <TextField
                      id="recipient_name"
                      label=""
                      value={values.gift_message}
                      autoFocus
                      className="bg_lighter_hippie_blue custom_label_text_input custom_title_gift_message custom_gift_message_textarea pt-0 pt-lg-2"
                      onChange={(e) =>
                        setValues({
                          ...values,
                          gift_message: e.target.value,
                        })
                      }
                      multiline
                    />
                    <ErrorMessageWrapper
                      touched={{ gift_message: true }}
                      errors={errors}
                      fieldName="gift_message"
                    />
                  </>
                )}
              </>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default GiftMessage;
