import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import PriceTable from "./components/PriceTable";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import RequestSignIn from "./components/RequestSignIn";
import { isTablet, isMobile } from "react-device-detect"

const LeftPart = ({
  formikValues,
  handleChange,
  handleSetValue,
  touched,
  errors,
  denomination,
}) => {
  const {user, loggedIn} = useSelector((state) => state.authentication);
  const NextButton = () => {
    return (
      <Button
        className={`form-control hippie-blue-button next-button ${!isTablet && 'ml-lg-5'}`}
        type="submit"
      >
        <b className ="title_next_btn">
          <IntlMessage id="common.next" />
        </b>
      </Button>
    );
  };

  return (
    <Row id="left-part-wrapper-step2" className={`${formikValues.prayer_customization?.religion}`}>
      <Col xs="12" className="d-block d-md-none custom_back_button_step_2">
        <Link to="/requests/step1?back=1" className="text-hippie-blue" onClick={()=> {
                formikValues.prayer_contribution = ""
                formikValues.total_contribution = ""
              }}>
          {"< Back"}
        </Link>
      </Col>
      <Col
        lg={isTablet ? { span: 12, offset: 0 } : { span: 10, offset: 1 }}
        md={isTablet ? { span: 12, offset: 0 } : { span: 10, offset: 2 }}
        xs={{ span: 12, offset: 0 }}
        className="p-0 padding-mobile"
      >
        {
          isTablet &&
          <span>
            <Link to="/requests/step1" className="text-hippie-blue" onClick={()=> {
                formikValues.prayer_contribution = ""
                formikValues.total_contribution = ""
              }}>
              {"< Back"}
            </Link>
          </span>
        }
        <h2 className="my-title mt-3 custom_title_left_step_2">
          <IntlMessage id="public.request.step2.left.title" />
        </h2>

        <div className="left-subtitle">
          <IntlMessage id="public.request.step2.left.subtitle" />
        </div>

        <PriceTable
          denomination={denomination}
          formikValues={formikValues}
          handleSetValue={handleSetValue}
          errors={errors}
        />

        { (!isMobile || isTablet) && (
        <>
        <Row className="form-group m-float-group d-block d-md-none">
          <Col xs="12">
            <NextButton />
          </Col>
          <div className="d-block d-md-none mt-2 mx-auto">
          { !loggedIn && <RequestSignIn />}
          </div>
        </Row>

        <div className="d-none d-md-block mt-5 step2-tablet-btn-next">
          {
            !isTablet &&
            <span>
              <Link to="/requests/step1" className="text-hippie-blue"  onClick={()=> {
                formikValues.prayer_contribution = ""
                formikValues.total_contribution = ""
              }} >
                {"< Back"}
              </Link>
            </span>
          }
          <NextButton  />
          <div className={isTablet ? '' : "ml-4"}>
          { !loggedIn && <RequestSignIn />}

          </div>
        </div>
        </>
        )}
      </Col>
    </Row>
  );
};

export default LeftPart;
