import _ from "lodash"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

let endpoints = {
  login_path: "/login",
  register_path: "/register",
  social_login_path: "/social_login",
  email_exists: "/check_email_exists",
  requests: "/prayer_requests",
  prayer_requests: "/prayer_requests",
  prayer_types: "/prayer_types",
  admin_prayer_requests: "/admin/prayer_requests",
  admin_prayer_partners: "/admin/prayer_partners",
  admin_prayer_partners_denominations: "/admin/prayer_partners/denominations",
  admin_users: "/admin/users",
  contribution: "/contributions",
  get_all_contributions: "/get_all_contributions",
  frequency: "/frequencies",
  user_path: "/users",
  admin_support_center: "/admin/support_centers",
  admin_reports: "/admin/reports",
  admin_request_reports_by_date: "/admin/reports/prayer_requests",
  admin_export_request_reports_by_date: "/admin/reports/prayer_requests/export",
  admin_export_partner_reports_by_date: "/admin/reports/prayer_partners/export",
  admin_partner_reports_by_date: "/admin/reports/prayer_partners",
  admins_path: "/admins",
  admins_register_path: "/admin/register",
  activation_code_path:"/get_user_by_activation_code",
  contact_page: "/contacts",
  admin_financial: "/admin/transactions",
  set_admin_financial: "/admin/transactions/partner",
  get_pp_list: "/admin/get_all_prayer_partners",
  get_requestID_list: "/admin/get_all_prayer_requests",
  pp_notification: "/prayer_partners/notifications",
  coupon_path: "/admin/coupons",
  about: "/about"
}

let API_ENDPOINTS = {}
_.forEach(endpoints, (value, key) => (API_ENDPOINTS[key] = `${value}`))

export const apiEndpoints = API_ENDPOINTS
