import React, { useRef, useState } from "react"
import { Card, Button, Collapse } from "react-bootstrap"

import IntlMessage from "@iso/components/intlMessage"
import EditIcon from "@material-ui/icons/Edit"
import { useDispatch, useSelector } from "react-redux"

import { TaglineMessage } from "./TaglineMessage"
import {
  updatePrayerProfile,
  updateAvatarPrayer,
} from "@iso/PrayerPartner/api_v1"
import { getPrayerProfile } from "@iso/PrayerPartner/api_v1";
import alertActions from "@iso/actions/Alert"
import "./styles.scss"
import ModalCropImage from "./ModalCropImg"
import DialogMessage from "./DialogMessage"
import { popupConfig } from "./constants"
import ProfileForm from "./ProfileForm"
import ConfirmModal from "@iso/components/Common/ConfirmModal";
import welcomePrayerPartnerActions from "@iso/actions/welcomePrayerPartnerActions"
import { sendDemo } from "@iso/PrayerPartner/api_v1"
import { history } from "@iso/helpers/history";
const { openAlert } = alertActions

const ProfileCard = ({ openProfile, profile, reloadProfile, setProfile }) => {
  const dispatch = useDispatch()
  const [getChangeAvatar, setGetChangeAvatar] = useState(false)
  const [oldProfile, setOldProfile] = useState(profile)
  const [getAvatar, setGetAvatar] = useState(profile?.avatar_pending)
  const [getStatus, setGetStatus] = useState(profile?.avatar_status)
  const [isEdit, setIsEdit] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [onShowModal, setOnShowModal] = useState(false)
  const [upImg, setUpImg] = useState()
  const [openDialog, setOpenDialog] = useState(false)
  const [configDialog, setConfigDialog] = useState({ ...popupConfig.success })
  const inputFile = useRef(null)

  const isPending = getStatus === "pending" || false
  const isApproval = getStatus === "approval" || false
  const [isSubmit, setIsSubmit] = React.useState(false)
  const justCreated = useSelector((reducer) =>  reducer.welcomePrayerPartnerReducer );
  const [isOpenCrtAccModal, setOpenCrtAccModal] = React.useState(false)

  React.useEffect(() => {
    if(isSubmit) setOpenCrtAccModal(justCreated.isOpenModal)
  },[isSubmit])

  React.useEffect(() => {
    if(getChangeAvatar){
      getPrayerProfile().then((res) => {
        setGetAvatar(res.data.data.avatar_pending);
        setGetStatus(res.data.data.avatar_status);
      });
    }
  },[getChangeAvatar])

	const handleAfterSubmit = (profile) => {
	  if (
		  oldProfile.tagline !== profile.tagline
	  ) {
		  setOpenDialog(true)
		  setConfigDialog({
			  ...popupConfig.tagline,
			  handleOK: () => setOpenDialog(false),
		  });
	  }
		if (
			oldProfile.first_name !== profile.first_name ||
			oldProfile.last_name !== profile.last_name ||
			oldProfile.phone_number !== profile.phone_number ||
			oldProfile.city !== profile.city ||
			oldProfile.country !== profile.country ||
			oldProfile.religion[0] !== profile.religion[0]
		) {
		  setOpenDialog(true)
		  setConfigDialog({
			  ...popupConfig.other_field,
			  handleOK: () => setOpenDialog(false),
		  });
	  }
		setOldProfile(profile);
	}
	const getResponseMessage = (message, result = []) => {
    for(let i in message){
      if(typeof message[i] == 'object'){
        getResponseMessage(message[i], result);
      }else if(typeof message[i] == 'string'){
        result.push(message[i])
      }
    }
    return result;
  }

  const handleSubmit = async (values) => {
    try {
      setLoading(true)
      updatePrayerProfile(values).then((res) => {
        setLoading(false)
        if (res.data.code === 200) {
          if (res.data.data.status === 'pending'){
            setOpenCrtAccModal(true)
          } else {
	          handleAfterSubmit(res.data.data);
          }

          dispatch(
            openAlert({
              message: " Account successfully update!",
              severity: "success",
            })
          )
          setIsEdit(false)
          reloadProfile()
        } else {
          let responseMessages = "Incorrect information!";
          if(res.data.code === 400 && res?.data?.message){
            responseMessages = getResponseMessage(res.data.message);
              
          }
          dispatch(
            openAlert({
              message: responseMessages,
              severity: "error",
            })
          )
        }
      })
    } catch (error) {
      dispatch(
        openAlert({
          message: "Incorrect information!",
          severity: "error",
        })
      )
      return
    }
  }

  const onButtonClick = (event) => {
    if (isPending || isApproval) {
      setOpenDialog(true)
      setConfigDialog({ ...popupConfig.confirm })
      return
    }
    inputFile.current.click()
  }

  const confirmChange = () => {
    setOpenDialog(false)
    inputFile.current.click()
  }

  const onChangeFile = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener("load", () => setUpImg(reader.result))
      reader.readAsDataURL(event.target.files[0])

      setOnShowModal(true)
    }
    event.target.value = null;
  }

  const saveChangeImg = (croppedImage) => {
    const formData = new FormData()
    formData.append("avatar", croppedImage)
    setOnShowModal(false)
    updateAvatar(formData)
    setGetChangeAvatar(false)
    // getPrayerProfile().then((res) => {
    //   setGetAvatar(res.data.data.avatar_pending);
    //   setGetStatus(res.data.data.avatar_status);
    // });
  }

  const handleCloseThanksBtn = () => {
    setOpenCrtAccModal(false)
    dispatch(welcomePrayerPartnerActions.closeModal())
  }

  const handleSendemo = () => {
    sendDemo();
    handleCloseThanksBtn();
    history.push('/prayer_partners')
  }
  const updateAvatar = (formData) => {
    try {
      updateAvatarPrayer(formData).then((res) => {
        const { code } = res.data
        if (code === 200) {
          // reloadProfile()
          setGetChangeAvatar(true)
          setOpenDialog(true)
          setConfigDialog({
            ...popupConfig.success,
            handleOK: () => setOpenDialog(false),
          })
        } else {
          dispatch(
            openAlert({
              message: "Update avatar error!",
              severity: "error",
            })
          )
          return
        }
      })
    } catch (err) {
      dispatch(
        openAlert({
          message: "Update avatar error!",
          severity: "error",
        })
      )
      return
    }
    getPrayerProfile().then((res) => {
      setGetAvatar(res.data.data.avatar_pending);
      setGetStatus(res.data.data.avatar_status);
    });
  }
  return (
    <div className="p-3">
      <Card.Title className="title p-0 m-0 px-3">
        <span>
          <IntlMessage id="prayer_partners.account_settings.profile.title" />
        </span>
        <Button
          className="float-right hover_pointer btn hippie-blue-link edit_btn"
          onClick={() => setIsEdit(true)}
        >
          <EditIcon className="pr-1 pb-1" />
          <IntlMessage id="prayer_partners.account_settings.profile.btn.edit" />
        </Button>
      </Card.Title>
      <Collapse in={openProfile}>
        <Card.Body>
          <ProfileForm
            getAvatar={getAvatar}
            handleSubmit={handleSubmit}
            isEdit={isEdit}
            profile={profile}
            isPending={isPending}
            setIsEdit={setIsEdit}
            isLoading={isLoading}
            onButtonClick={onButtonClick}
          />
          <input
            type="file"
            id="file"
            ref={inputFile}
            onChange={onChangeFile}
            style={{ display: "none" }}
          />

          <ConfirmModal
          open={isOpenCrtAccModal}
          handleConfirm={() => handleSendemo()}
          handleClose = { () => handleCloseThanksBtn()}
          title={<IntlMessage id="confirm_modal.thank.submit.title"/>}
          content={<IntlMessage id="confirm_modal.thank.submit.content" />}
          goToBtnText={<IntlMessage id="confirm_modal.thank.submit.button" />}
          />

          {!isEdit && (
            <TaglineMessage
              status={profile?.tagline_status || ""}
              suggest={profile?.tagline_suggest || ""}
              show={profile?.show_tagline || ""}
            />
          )}
	        
          <ModalCropImage
            source={upImg}
            onShow={onShowModal}
            onHide={() => setOnShowModal(false)}
            saveChangeImg={saveChangeImg}
          />

          <DialogMessage
            open={(openDialog)}
            handleClose={() =>{
              setOpenDialog(false)
              setOnShowModal(false)
            }}
            handleOK={confirmChange}
            {...configDialog}
          />

        </Card.Body>
      </Collapse>
      
      {
      
      
      }
     
    </div>
  )
}

export default ProfileCard
