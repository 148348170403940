import { IDLING_DAYS_EXPIRE_TOKEN } from "@iso/constants";

export function setToken(currentUser, option = { remoteLogin: false }) {
  let accessToken = currentUser.access_token;
  sessionStorage.setItem("token", accessToken);
  sessionStorage.setItem("remoteLogin", option.remoteLogin);
  if (!option.remoteLogin) {
    if (currentUser.rememberMe) {
      let token = {
        value: accessToken,
        timestamp: new Date().getTime(),
      };

      localStorage.setItem("token", JSON.stringify(token));
    } else {
      document.cookie = `token=${accessToken}; expires=0; path=/`;
    }
  }
}
export function removeToken() {
  const remoteLogin = sessionStorage.getItem("remoteLogin") == "true"
  sessionStorage.removeItem("token");
  if(!remoteLogin){
    localStorage.removeItem("token");
  }
}
export function getToken(option = { remoteLogin: false }) {
  if (option.remoteLogin) {
    if (sessionStorage.getItem("token")) {
      return sessionStorage.getItem("token");
    }
    else {
      sessionStorage.setItem("user", null);
    }
  } else {
    if (getCookie("token")) {
      return getCookie("token");
    }
    if (localStorage.getItem("token")) {
      try {
        const token = JSON.parse(localStorage.getItem("token"));
        const oneDay = 24 * 60 * 60 * 1000;
        const now = new Date().getTime();
        if (
          token &&
          (now - token.timestamp) / oneDay <= IDLING_DAYS_EXPIRE_TOKEN
        ) {
          return token.value;
        }
      } catch (err) {
        console.log(err)
        removeToken();
      }
    }
  }
}

//function getCookie
function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1);
    if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
  }
  return "";
}

export const clearUserData = () => {
  const remoteLogin = sessionStorage.getItem("remoteLogin") == "true";
  removeToken();
  sessionStorage.removeItem("user");
  if (!remoteLogin) {
    document.cookie = "user=; expires=0; path=/";
    localStorage.removeItem("user");
  }
}
