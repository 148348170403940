import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import AuthService from "@iso/services/AuthService";
import AdminsService from "@iso/services/AdminsService";
import UserService from "@iso/services/UserService";
import ProcessRequest from "@iso/utils/ProcessRequest";
import { history } from "@iso/helpers";
import { userRoleConstants } from "@iso/constants";
import ConfirmModal from "@iso/components/Common/ConfirmModal";
import IntlMessage from "@iso/components/intlMessage";
import { REDIRECT_URL } from "@iso/constants";
import { Form } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import banAccountActions from "@iso/actions/banAccountActions";

const LoginAs = (props) => {
  const {
    className,
    id,
    role,
    allowRemove = true,
    allowLoginAs = true,
    allowResetPassword = false,
    disableAdmin,
    allowBan = false,
  } = props;

  const dispatch = useDispatch();

  const [sendingRequest, setSendingRequest] = React.useState();

  const [action, setAction] = React.useState("");
  const [openDeletePartnerModal, setOpenDeletePartnerModal] = React.useState(
    false
  );
  const [openDeleteUserModal, setOpenDeleteUserModal] = React.useState(false);
  const [openDisableAdminModal, setOpenDisableAdminModal] = React.useState(
    false
  );
  const [openDeleteAdminModal, setOpenDeleteAdminModal] = React.useState(false);
  const [
    openResetUserPasswordModal,
    setOpenResetUserPasswordModal,
  ] = React.useState(false);
  const [openBanAccountModal, setOpenBanAccountModal] = React.useState(false);
  const [openBanUserModal, setOpenBanUserModal] = React.useState(false);

  const [optionBanAccount, setOptionBanAccount] = React.useState();

  const { account_id, is_banned } = useSelector(
    (state) => state.banAccountReducers,
    shallowEqual
  );

  const toggleBanStr = account_id == id && is_banned ? "unban" : "ban";

  const handleChange = (event) => {
    const value = event.target.value;
    setAction(event.target.value);
    switch (value) {
      case userRoleConstants.PRAYER_ROLE:
      case userRoleConstants.USER_ROLE:
        handleLoginAs(value);
        break;
      case `remove_${userRoleConstants.PRAYER_ROLE}`:
        setOpenDeletePartnerModal(true);
        break;
      case `remove_${userRoleConstants.USER_ROLE}`:
        setOpenDeleteUserModal(true);
        break;
      case `remove_${userRoleConstants.ADMIN_ROLE}`:
        setOpenDeleteAdminModal(true);
        break;
      case `disable_${userRoleConstants.ADMIN_ROLE}`:
        setOpenDisableAdminModal(true);
        break;
      case `reset_password_${userRoleConstants.USER_ROLE}`:
        setOpenResetUserPasswordModal(true);
        break;
      case `${toggleBanStr}_${userRoleConstants.PRAYER_ROLE}`:
        handleOpenToggleBanAccountModal({
          role: userRoleConstants.PRAYER_ROLE,
          open: true,
        });
        break;
      case `${toggleBanStr}_${userRoleConstants.USER_ROLE}`:
        handleOpenToggleBanAccountModal({
          role: userRoleConstants.USER_ROLE,
          open: true,
        });
        break;
      default:
        break;
    }
  };

  const handleOpenToggleBanAccountModal = (opts) => {
    setOpenBanAccountModal(opts.open);
    setOptionBanAccount({
      role: opts.role,
      body: { action: toggleBanStr, id, [`is_${toggleBanStr}_ip`]: is_banned },
    });
  };

  const handleLoginAs = (role) => {
    window.open(`${REDIRECT_URL[role]}?loginAsId=${id}&role=${role}`);
    setAction("");
  };

  const handleDeletePrayer = () => {
    setSendingRequest(true);
    AuthService.deletePrayerPartner(id).then((res) => {
      ProcessRequest.run({
        res,
        hasNoti: true,
        successCallback: (data) => {
          history.push("/admin/prayer_partners");
          setSendingRequest(false);
        },
      });
    });
  };

  const handleDeleteUser = () => {
    setSendingRequest(true);
    AuthService.adminDeleteUser(id).then((res) => {
      ProcessRequest.run({
        res,
        hasNoti: true,
        successCallback: (data) => {
          history.push("/admin/users");
          setSendingRequest(false);
        },
      });
    });
  };

  const handleDisableAdmin = () => {
    setSendingRequest(true);
    AuthService.adminDisableAdmin(id).then((res) => {
      ProcessRequest.run({
        res,
        hasNoti: true,
        successCallback: (data) => {
          history.push("/admin/adminsTab");
          setSendingRequest(false);
        },
      });
      setSendingRequest(false);
      setOpenDisableAdminModal(false);
    });
  };

  const handleDeleteAdminAccount = () => {
    setSendingRequest(true);
    AdminsService.deleteAdminAccount(id).then((res) => {
      ProcessRequest.run({
        res,
        hasNoti: true,
        successCallback: (data) => {
          history.push("/admin/adminsTab");
          setSendingRequest(false);
        },
        errorCallback: () => {
          setSendingRequest(false);
        }
      });
    });
  };

  const handleSendResetPasswordRequest = () => {
    setSendingRequest(true);
    UserService.adminResetUserPassword(id).then((res) => {
      ProcessRequest.run({
        res,
        hasNoti: true,
        successCallback: () => {
          setOpenResetUserPasswordModal(false);
          setSendingRequest(false);
        },
      });
    });
  };

  const handleBanAccountRequest = () => {
    setSendingRequest(true);
    AdminsService.toggleBanAccount(
      optionBanAccount.role,
      optionBanAccount.body
    ).then((res) => {
      ProcessRequest.run({
        res,
        hasNoti: true,
        successCallback: (data) => {
          setOpenBanAccountModal(false);
          setSendingRequest(false);
          dispatch(banAccountActions.setBanAccountData(data.data));
        },
      });
    });
  };

  return (
    <>
      <Select
        labelId="select_login_as-label"
        id="select_login_as"
        value={action}
        onChange={handleChange}
        displayEmpty
        className={`select_login_as ${className}`}
      >
        <MenuItem value="">
          <em>Select action</em>
        </MenuItem>

        {disableAdmin && (
          <MenuItem value={`disable_${role}`}>
            <IntlMessage id={`login_as.select_item.disable_${role}`} />
          </MenuItem>
        )}

        {allowLoginAs && (
          <MenuItem value={role}>
            <IntlMessage id={`login_as.select_item.${role}`} />
          </MenuItem>
        )}

        {allowRemove && (
          <MenuItem value={`remove_${role}`}>
            <IntlMessage id={`login_as.select_item.remove_${role}`} />
          </MenuItem>
        )}

        {allowResetPassword && (
          <MenuItem value={`reset_password_${role}`}>
            <IntlMessage id={`login_as.select_item.reset_password`} />
          </MenuItem>
        )}

        {allowBan && (
          <MenuItem value={`${toggleBanStr}_${role}`}>
            <IntlMessage id={`login_as.select_item.${toggleBanStr}.${role}`} />
          </MenuItem>
        )}
      </Select>

      {/* Need to refactor by redux */}

      {openDeletePartnerModal && (
        <ConfirmModal
          open={openDeletePartnerModal}
          handleConfirm={handleDeletePrayer}
          handleClose={() => {
            setAction("");
            setOpenDeletePartnerModal(false);
          }}
          title={<IntlMessage id="login_as.modal.delete.title" />}
          okText={<IntlMessage id="common.yes" />}
          cancelText={<IntlMessage id="common.no" />}
          content={<IntlMessage id="login_as.modal.delete.content" />}
          sendingRequest={sendingRequest}
        />
      )}

      {openDeleteUserModal && (
        <ConfirmModal
          open={openDeleteUserModal}
          handleConfirm={handleDeleteUser}
          handleClose={() => {
            setAction("");
            setOpenDeleteUserModal(false);
          }}
          title={<IntlMessage id="login_as.modal.delete_user.title" />}
          content={<IntlMessage id="login_as.modal.delete_user.content" />}
          okText={<IntlMessage id="common.ok" />}
          cancelText={<IntlMessage id="common.cancel" />}
          sendingRequest={sendingRequest}
        />
      )}

      {openDisableAdminModal && (
        <ConfirmModal
          open={openDisableAdminModal}
          handleConfirm={handleDisableAdmin}
          handleClose={() => {
            setAction("");
            setOpenDisableAdminModal(false);
          }}
          title={<IntlMessage id="login_as.modal.disable_admin.title" />}
          okText={<IntlMessage id="common.ok" />}
          cancelText={<IntlMessage id="common.cancel" />}
          content={<IntlMessage id="login_as.modal.disable_admin.content" />}
          sendingRequest={sendingRequest}
        />
      )}

      {openDeleteAdminModal && (
        <ConfirmModal
          open={openDeleteAdminModal}
          handleConfirm={handleDeleteAdminAccount}
          handleClose={() => {
            setAction("");
            setOpenDeleteAdminModal(false);
          }}
          title={<IntlMessage id="confirm_modal.delete.admin.account.title" />}
          content={
            <IntlMessage id="confirm_modal.delete.admin.account.content" />
          }
          okText={<IntlMessage id="common.ok" />}
          cancelText={<IntlMessage id="common.cancel" />}
          sendingRequest={sendingRequest}
        />
      )}

      {openResetUserPasswordModal && (
        <ConfirmModal
          open={openResetUserPasswordModal}
          handleConfirm={handleSendResetPasswordRequest}
          handleClose={() => {
            setAction("");
            setOpenResetUserPasswordModal(false);
          }}
          title={<IntlMessage id="confirm_modal.user.reset_password.title" />}
          content={
            <IntlMessage id="confirm_modal.user.reset_password.content" />
          }
          okText={<IntlMessage id="common.yes" />}
          cancelText={<IntlMessage id="common.no" />}
          sendingRequest={sendingRequest}
        />
      )}

      {openBanAccountModal && (
        <ConfirmModal
          open={openBanAccountModal}
          handleConfirm={handleBanAccountRequest}
          handleClose={() => {
            setAction("");
            setOpenBanAccountModal(false);
          }}
          title={
            <IntlMessage
              id={`confirm_modal.prayer-partner.${toggleBanStr}.account.title`}
            />
          }
          content={
            <IntlMessage
              id={`confirm_modal.prayer-partner.${toggleBanStr}.account.content`}
            />
          }
          contentFooter={
            <>
              {!is_banned && (
                <Form.Check
                  custom
                  type="checkbox"
                  className="checkbox_custom"
                  name="ban-prayerPartner"
                  size="sm"
                  onChange={(event) => {
                    setOptionBanAccount({
                      ...optionBanAccount,
                      body: {
                        ...optionBanAccount.body,
                        is_ban_ip: event.target.checked,
                      },
                    });
                  }}
                  label={
                    <IntlMessage id="confirm_modal.prayer-partner.ban.account.footer.content" />
                  }
                  id="ban-prayerPartner"
                />
              )}
            </>
          }
          okText={<IntlMessage id="common.yes" />}
          cancelText={<IntlMessage id="common.no" />}
          sendingRequest={sendingRequest}
        />
      )}
    </>
  );
};

export default LoginAs;
