import React from "react";
import { Modal } from "react-bootstrap";
import { setCurrentUser } from "@iso/actions/User";
import AddFormSignIn from "./AddFormSignIn";
import { useSelector, useDispatch } from "react-redux";
import actionsSignIn from "@iso/actions/signinmodal";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import AuthService from "@iso/services/AuthService";
import { userRoleConstants } from "@iso/constants/user";
import alertActions from "@iso/actions/Alert";
import google_logo from "@iso/assets/images/Google-logo.png";
import facebook_logo from "@iso/assets/images/facebook-logo.png";
import IntlMessage from "@iso/components/intlMessage";
import { isMobile } from 'react-device-detect';
import { connect } from "react-redux";
import gtag from 'ga-gtag'
import { recommended_events } from "@iso/constants";
import { fb_pixel_event } from "@iso/constants";
import ReactPixel from 'react-facebook-pixel';


import "./style.scss";

const { closeModal } = actionsSignIn;
const facebookID = process.env.REACT_APP_FACEBOOK_ID;
const googleID = process.env.REACT_APP_GOOGLE_ID;
const { openAlert } = alertActions;
ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, fb_pixel_event.advanced_matching, fb_pixel_event.options);

function CModalFormSignIn(props) {
  const { handleSwitchRegister } = props;
  const dispatch = useDispatch();
  const showModal = useSelector((state) => state.signinmodal);
  let socialGGobj = {
    social_type: "",
    social_id: "",
    social_name: "",
    social_email: "",
  };

  const handleClose = () => {
    dispatch(closeModal());
  };

  const responseGoogle = (response) => {
    const profileObj = response.profileObj;
    socialGGobj.social_id = profileObj.googleId;
    socialGGobj.social_name = profileObj.name;
    socialGGobj.social_email = profileObj.email;
    socialGGobj.social_type = "google";
    loginSocialNetwork(socialGGobj);
  };

  const responseFacebook = (response) => {
    console.log('===login facebook 2')
    const profileObj = response;
    socialGGobj.social_id = profileObj.userID;
    socialGGobj.social_name = profileObj.name;
    socialGGobj.social_email = profileObj?.email;
    socialGGobj.social_type = "facebook";
    loginSocialNetwork(socialGGobj);
  };

  const loginSocialNetwork = (object) => {
    gtag('event', recommended_events.LOGIN, {
      method : object.social_type
    });
    ReactPixel.track(fb_pixel_event.custom_events.LOGIN, {
      method : object.social_type
    });
    AuthService.loginSocialApi(object).then((res) => {
      if (!res) {
        dispatch(
          openAlert({
            message: "Log In failed",
            severity: "error",
          })
        );
      }
      const { code, data } = res.data;
      if (code === 200 && (data.role === userRoleConstants.USER_ROLE || data.role === userRoleConstants.PRAYER_ROLE) ) {
        props.setCurrentUser({ ...data, rememberMe: null })
      } else if(code === 403){
          dispatch(
            openAlert({
              message: <IntlMessage id ="admin.ban.account.error.message" />,
              severity: "error",
            })
          );
      } else{
        dispatch(
          openAlert({
            message: "Log In failed",
            severity: "error",
          })
        );
      }
      }
    );
  };

  return (
    <React.Fragment>
      <Modal.Header id="modalSignInHeader" closeButton={isMobile  ? true : false}>
        <Modal.Title className="modalSignInTitle">
          <h2 className="font-weight-bold font-size-36px custom_title_welcome_back">
            <IntlMessage id="public.request.form.signin_title" />
          </h2>
          <p className="font_size_18px custom_form_signin_social_title">
            <IntlMessage id="public.request.form.signin_social_title" />
          </p>

          <div className="btn-group">
            <div className="btn-group">
              <FacebookLogin
                appId={facebookID}
                textButton="Facebook"
                callback={responseFacebook}
                scope="public_profile, email"
                fields="name,email,picture"
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    type="button"
                    className="btn btn-outline-primary font-weight-bold ml-0 color-fb-blue font-size-20px"
                  >
                    <img
                      src={facebook_logo}
                      alt="facebook Logo"
                      className="float-left"
                    />
                    <span className="facebook-text">Facebook</span>
                  </button>
                )}
              />
            </div>

            <div className="btn-group">
              <GoogleLogin
                clientId={googleID}
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    type="button"
                    className="custom_button_google_sign_in btn btn-outline-success font-weight-bold text-dark mr-0 font-size-20px"
                  >
                    <img
                      src={google_logo}
                      alt="google Logo"
                      className="float-left mt-1"
                    />
                    <span className="facebook-text">Google</span>
                  </button>
                )}
                buttonText="Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
              />
            </div>

            <div className="bottom-border-header">
              <IntlMessage id="public.request.form.signin_email_title" />
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalFormInputContainer mb-3">
        <AddFormSignIn
          handleSwitchRegister={handleSwitchRegister}
          sendToForgotPassword={props.sendToForgotPassword}
        />
      </Modal.Body>
    </React.Fragment>
  );
}

const mapStoreToProps = (store) => ({})

const mapDispatchToProps = {
  setCurrentUser: setCurrentUser,
}

const ModalFormSignIn = connect(mapStoreToProps, mapDispatchToProps)(CModalFormSignIn)
export default ModalFormSignIn;
