import React, { useEffect } from 'react'
import { Button } from "react-bootstrap";
import { useHistory } from 'react-router';
import requestStepActions from "@iso/actions/RequestStepActions";
import {initialState } from '@iso/reducers/request_step.reducer'
import { useDispatch } from 'react-redux';
import { isMobile, isTablet } from 'react-device-detect';
import IntlMessage from "@iso/components/intlMessage";
import { useSelector } from "react-redux";

const RequestDetails =  (props) => {
  const {email, requestId = ''} = props
  const history = useHistory()
  const dispatch = useDispatch()
  const {user, loggedIn} = useSelector((state) => state.authentication);
  const handleNewPrayer = () => {
    dispatch(requestStepActions.setDataStep(initialState))
    dispatch(requestStepActions.resetData())
    history.push("/requests/step1");
  }

    return        <div className="request-details">
    <h2 className="confirm-message text-center text-sm-left"><IntlMessage id="public.request.step4.request.confirmed"/></h2>

    <div className="request-info mt-4">
      <div className="request-number d-flex"> 
      <p className="mr-1"><IntlMessage id="public.request.step4.request.transaction_id"/> </p><b>{requestId}</b></div>
      <div className="request-email"><p><IntlMessage id="public.request.step4.request.email"/> {loggedIn ? (user?.email) : email}</p></div>
    </div>

<div className="request-notice-message">
  {
    (isTablet)
      ?
      <>
        <p style={{width: "303px"}}><IntlMessage id="public.request.step4.complete.request.message"/></p>
        <p class="step-4-redmine-spam-email"><IntlMessage id="public.request.step4.complete.redmine.spam.email"/></p>
      </>
      :
    ((isMobile) ?
      <>
        <p style={{width: "303px"}}><IntlMessage id="public.request.step4.complete.request.message"/></p>
        <p class="step-4-redmine-spam-email"><IntlMessage id="public.request.step4.complete.redmine.spam.email"/></p>
      </>
      :
      <>
        <p><IntlMessage id="public.request.step4.complete.request.message"/></p>
        <p class="step-4-redmine-spam-email"><IntlMessage id="public.request.step4.complete.redmine.spam.email"/></p>
      </>  )
  }
</div>

<Button onClick={() => handleNewPrayer()} className="request-new-button btn btn-primary hippie-blue-button "><IntlMessage id="public.request.step4.request.new"/></Button>
    </div>
}

export default RequestDetails