import React from "react";
import {DATE_FORMAT_PRAYER_REQUEST} from "./constants";
import {Col, Container, Modal, Row} from "react-bootstrap";
import moment from "moment";
import IntlMessage from "@iso/components/intlMessage";
import "./ModalRequestInfo.scss";

const ModalRequestInfo = ({request, chainRequest, open, setOpen}) => {
  const forSomeone = request.recipient === "for_someone"
  const isGift = !!request.is_gift
  const time_zone = -(new Date().getTimezoneOffset() / 60);

  const showChainRequest = () => {
    if (chainRequest && chainRequest.length > 0) {
      return chainRequest.map((request, index) =>
        <a target="_blank" href={`/prayer_requests/${request}`}>{request}{(index !== (chainRequest.length -1)) ? ',' : '' } </a>
      );
    }else {
      return (
        <span className={`font-weight-bold`}>(None)</span>
      );
    }
  }

  const showTitleName = () => {
    console.log("request",request);
    if(request.recipient == 'for_someone') {
      return (
        <h4 className={`font-weight-bold`} style={{'margin-top':'-20px'}}>{
          (request.client_name !== 'Guest User' 
          ? (request.is_gift) 
              ? `${request.client_name}'s` 
              : `${request.client_name}'s`
          : (request.is_gift) 
              ? (`${request.client_name + ' (' + request.sender_name }'s)`) 
              : (`${request.client_name + ' (' + ((request.sender_name !== null) ? request.sender_name : request.recipient_name) }'s)`)) + ' Prayer Request'}</h4>
      )
    } else {
      return (
        <h4 className={`font-weight-bold`} style={{'margin-top':'-20px'}}>{
          (request.client_name !== 'Guest User' 
            ? `${request.client_name}'s` 
            : (request.is_gift) 
                ? (`${request.client_name + ' (' + request.sender_name }'s)`) 
                : (`${request.client_name + ' (' + request.recipient_name }'s)`)) + ' Prayer Request'}</h4>
      )
    }
    
  }

  const getFrequencyLabel = (frequencyID) => {
    switch(frequencyID){
      case 'one_time':
        return (
          <IntlMessage id="public.request.step2.frequency.eastern-orthodox.one_time" />
        )
      case 'daily':
        return (
          <IntlMessage id="public.request.step2.frequency.eastern-orthodox.daily_1_week" />
        )
      case 'weekly':
        return (
          <IntlMessage id="public.request.step2.frequency.eastern-orthodox.weekly_1_month" />
        )
      case 'monthly':
        return (
          <IntlMessage id="public.request.step2.frequency.eastern-orthodox.monthly_1_year" />
        )
    }
  }

  const dueDateFormat = () => {
    let time = moment.utc(request.end_date).utcOffset(time_zone).format(DATE_FORMAT_PRAYER_REQUEST)
    return (
      <>
        {`${time} `}
        <IntlMessage id={request.is_auto_assigned_end_date
          ? 'admin.requests.details.due_date_system'
          : 'admin.requests.details.due_date_user'
        }/>
      </>
    )
  }
  const templateITem = (title, value) => {
    return (
      <Row className="mb-3 request_item_wrapper">
        <Col sm="4" lg="6" className="bullet">
          <span className="font-weight-bold">
            <IntlMessage id={title}/>
          </span>
        </Col>
        <Col sm="8" lg="6" className= {`margin-left-content`}>
          {value}
        </Col>
      </Row>
    )
  }

  const handleCloseModal = () => {
    setOpen(!open)
  }

  return (
    <Modal
      show={open}
      onHide={handleCloseModal}
      className="request_detail_modal_wrapper"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <IntlMessage id="admin.requests.details.request_details"/>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="ModalRequestInfo">
          <Container className="mt-2 request-details">
            {showTitleName()}
            {templateITem('prayer_partners.table.title.prayer_number', request.id)}
            {templateITem('prayer_partners.table.title.related_chain_request', showChainRequest())}

            {forSomeone && templateITem('admin.requests.details.recipient', 
              isGift ? (<IntlMessage
                id="admin.requests.details.recipient_for_some_one"
                values={{recipient_name: request.recipient_name }}
              />) : (
                <IntlMessage
                  id="admin.requests.details.recipient_for_some_one_not_gift"
                  values={{recipient_name: request.recipient_name }}
                />
              )
            )}
            {forSomeone && templateITem(
              'admin.requests.details.prayer_as_a_gift',
              isGift ? (<IntlMessage id="common.yes"/>) : (<IntlMessage id="common.no"/>)
            )}
            {/* {(forSomeone && isGift) && templateITem('admin.requests.details.recipient_email', request.recipient_email)} */}

            {!forSomeone && templateITem(
              'admin.requests.details.recipient',
              <IntlMessage
                id="admin.requests.details.recipient_for_myself"
                values={{recipient_name: (request.client_id === null ? request.recipient_name  : request.client_name)}}
              />
            )}
            {!forSomeone && templateITem(
              'admin.requests.details.prayer_as_a_gift',
              isGift ? (<IntlMessage id="common.yes"/>) : (<IntlMessage id="common.no"/>)
            )}
            {!forSomeone && isGift && templateITem('admin.requests.details.recipient_email', request.recipient_email)}

            {templateITem('admin.requests.details.reason_for_prayer', (request.reason_translate || request.reason))}
            {templateITem('admin.requests.details.due_date', dueDateFormat())}
            {templateITem('admin.requests.details.denomination', request.denomination_label)}
            {templateITem('admin.requests.details.message_tone', request.message_tone_label)}
            {templateITem('admin.requests.details.type', (request.type && request.type.name))}
            {templateITem('admin.requests.details.special_request', (request.special_request_translate || request.special_request))}
            {templateITem('admin.requests.details.contribution_option', request.contribution && request.contribution.name)}
            {templateITem('admin.requests.details.frequency', getFrequencyLabel(request.frequency))}

          </Container>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default ModalRequestInfo;