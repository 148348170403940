import React from 'react'
import { Modal, Col } from "react-bootstrap"
import google_logo from "@iso/assets/images/Google-logo.png";
import facebook_logo from "@iso/assets/images/facebook-logo.png";
import AddFormSignUp from "../SignUp/FormSignUp"
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {useSelector, useDispatch} from 'react-redux';
import alertActions from "@iso/actions/Alert"
import { userRoleConstants } from "@iso/constants/user"
import { setCurrentUser } from "@iso/actions/User"
import AuthService from "@iso/services/AuthService"
import IntlMessage from "@iso/components/intlMessage"
import SignUpIcon from "@iso/assets/images/signUp-icon.svg"
import welcomePrayerCloudActions from '@iso/actions/WelcomePrayerCloudActions'
import './styles.scss'
import { isMobile } from 'react-device-detect';
import actionsSignIn from "@iso/actions/signinmodal";
import gtag from 'ga-gtag';
import { recommended_events } from "@iso/constants";
import { fb_pixel_event } from "@iso/constants";
import ReactPixel from 'react-facebook-pixel';

ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, fb_pixel_event.advanced_matching, fb_pixel_event.options);

const { openAlert } = alertActions;
const facebookID = process.env.REACT_APP_FACEBOOK_ID;
const googleID = process.env.REACT_APP_GOOGLE_ID;

const ReceiveConfirmForm = (props) => {
  const {step, title } = props
  const dispatch = useDispatch()
    const {handleContinueAsGuest, handleSwitchSignIn} = props
    let socialGGobj ={
      social_type : "",
      social_id : "",
      social_name: "",
      social_email: ""
    };

  const responseGoogle = (response) => {
    const profileObj = response.profileObj;
    socialGGobj.social_id = profileObj.googleId;
    socialGGobj.social_name = profileObj.name;
    socialGGobj.social_email = profileObj.email;
    socialGGobj.social_type  = "google";
    loginSocialNetwork(socialGGobj);

}


const responseFacebook = (response) => {
  console.log('=== login facebook 1')
  const profileObj = response;
  socialGGobj.social_id = profileObj.userID;
  socialGGobj.social_name = profileObj.name;
  socialGGobj.social_email = profileObj?.email;
  socialGGobj.social_type  = "facebook";
  loginSocialNetwork(socialGGobj);
}

const socialRender = () => {
  return(
    <>
    <FacebookLogin
      appId= {facebookID}
      textButton="Facebook"
      callback={responseFacebook}
      scope= "public_profile,email"
      fields="name,email,picture"
      render={renderProps => (
        <button onClick={renderProps.onClick}
          type="button"
          className="btn btn-outline-primary font-weight-bold ml-0 text-blue">
          <img src = {facebook_logo}
          width ="25px"
          height ="25px"
          alt ="facebook Logo"
          className = "float-left"/>
          <span className="social-text">Facebook</span></button>
      )}
      />

      <GoogleLogin
        clientId= {googleID}
        render={renderProps => (
          <button  onClick={renderProps.onClick}
            type="button"
            className="btn btn-outline-success font-weight-bold text-dark mr-0">
            <img src = {google_logo}
            width ="25px"
            height ="25px"
            alt ="google Logo"
            className = "float-left"/>
            <span className="social-text">Google</span>
          </button>
        )}
        buttonText="Google"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        approvalPrompt="force"
        prompt='consent'
        cookiePolicy={'single_host_origin'}
      />
    </>
  )
}

const openSignInModal = () => {
  dispatch(
    actionsSignIn.openModal({
      type: actionsSignIn.SHOW_MODAL,
      title: title,
      backDropClassName: 'recieveYourConfirmModalBackDrop'
    })
  );
};

const loginSocialNetwork = (object) => {
  gtag('event', recommended_events.SIGN_UP, {
    method : object.social_type
  });
  ReactPixel.track(fb_pixel_event.recommended_events.SIGN_UP, {
    method : object.social_type
  }); 
  AuthService.loginSocialApi(object).then((res) => {
    if(!res){
      dispatch(
        openAlert({
         message: "Log In failed",
          severity: "error",
        })
      )
    }
    const { code, data } = res.data;
    if (code === 200 && data.role === userRoleConstants.USER_ROLE) {
      dispatch(setCurrentUser({ ...data, rememberMe: null }));
      // Welcome modal
      if(title === 'user.confirm.form.receive'){
        dispatch(
          welcomePrayerCloudActions.openModal({
            type: welcomePrayerCloudActions.SHOW_PRAYER_CLOUD_MODAL,
            payload : {
              isOpen: true,
              isHeader: true,
            },
          })
        )
      }else{
        dispatch(
          welcomePrayerCloudActions.openModal({
            type: welcomePrayerCloudActions.SHOW_PRAYER_CLOUD_MODAL,
            payload :{
              isOpen: true,
              isSubcribe: false,
            }
          })
        )
      }

    }else if(code === 400) {
      dispatch(
        openAlert({
         message: "Email already exists",
          severity: "error",
        })
      )
    }
    else {
      dispatch(
        openAlert({
         message: "Email already exists",
          severity: "error",
        })
      )
    }
  })
}

    return (
      <div id="receive_confirm_modal">
         <Modal.Header closeButton={isMobile && title !== 'user.confirm.form.receive.confirm' ? true : false}> </Modal.Header>
         {
           title === 'user.confirm.form.receive' ?
           <>
            <div className="d-none d-md-block text-center mb-0 mb-md-2">
              <img src={SignUpIcon}/>
            </div>
            <h2 className = "title-sign-up">
              <IntlMessage id={title} />
            </h2>
          </>
          :
          <h2 className = {`title-sign-up ${title === 'user.confirm.form.receive.confirm' &&  'mt-3'} `}>
              <IntlMessage id={title} />
          </h2>
         }


    <Modal.Body>
      <Modal.Title className ="modalSignInTitle ">
      <p className="subtitle text-center">
          <IntlMessage id="user.confirm.form.socials" />
      </p>
      <div className ="btn-group sign-in-social">
      {socialRender()}
      <div className ="bottom-border-header">
        {
          title !== 'user.confirm.subscribe.title' ?
          <>
            <span className="mx-3 d-none d-md-block"><IntlMessage id="public.request.form.signup_email_title"/></span>
            <span className="mx-3 d-block d-md-none"><IntlMessage id="public.request.form.signin_email_title"/></span>
          </>
          : <span className="mx-3"><IntlMessage id="public.request.form.signin_email_title"/></span>
        }

      </div>
      </div>
      </Modal.Title>
  <AddFormSignUp handleSwitchSignIn={handleSwitchSignIn} title = {title} step={step}/>
   </Modal.Body>

   {  step === 4 &&
    <>
    <div  className="switch-signIn-button">
          <IntlMessage id="user.confirm.form.policy6" /> &nbsp;
          <b onClick={openSignInModal} className="hippie-blue-link pointer">
            <IntlMessage id="sidebar.public.signin" />
          </b>
    </div>
    <p className="text-center switch-button" onClick={handleContinueAsGuest}>
    <IntlMessage id="user.confirm.form.continue.guest" /> &gt;</p>
    </>
  }
   </div>
   )
}

export default ReceiveConfirmForm
