import "./ReceiveYourConfirmation.scss";
import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Checkbox, Grid } from "@material-ui/core";
import { isMobile, isMobileSafari, isTablet } from "react-device-detect";
import CheckboxCheckedIcon from '@iso/assets/images/checkbox-icon-checked.svg'
import CheckboxIcon from '@iso/assets/images/checkbox-icon.svg'
import IntlMessage from "@iso/components/intlMessage"
import AuthService from "../../../../../services/AuthService";
import { setIsSaveCard, removeCardData } from "@iso/actions/CardDataActions";
import { useDispatch } from "react-redux"

const ReceiveYourConfirmation = (props) => {
  const isDesktop = !isMobile && !isMobileSafari && !isTablet
  const { emailExisted, setEmailExisted } = props;

  const [errorEmail, setErrorEmail] = useState(false)
  const [valueEmail, setValueEmail] = useState("")
  const [registerData, setRegisterData] = useState({})
  const dispatch = useDispatch()
  const handleOnChange = (e) => {
    let data = { ...props.formData };
    let value = e.target.value;
    let name = e.target.name;
    setRegisterData({ ...data, [name]: value })
    if (name === 'keep_me') {
      value = e.target.checked
    }
    if (name === 'email') {
      let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let hasError = !value;

      props.setErrorReceiveEmail(hasError ? { field: 'email', message: 'common.your_email_error' } : null)
      if (value) {
        hasError = !reg.test(value);
        if (hasError) {
          props.setErrorReceiveEmail({ field: 'email', message: 'common.your_email_format_error' })
        }
      }

      setErrorEmail(hasError)
      setValueEmail(value)
      if (!hasError) {
        checkEmailExisted(value)
      } else {
        setEmailExisted(false)
      }
    }
    data[name] = value;
    props.setFormData(data);

    if (!data.email) {
      props.setErrorReceiveEmail({ field: 'email', message: 'common.your_email_error' })
    } else {
      // props.setErrorReceiveEmail(emailExisted ? 'common.your_email_address_exist' : null)
    }
    props.setPayButtonClicked(false);
  }

  const checkEmailExisted = (email) => {
    AuthService.checkEmailExistsApi(email).then((resp) => {
      setEmailExisted(resp.data.existed)
      if (resp.data.existed) {
        props.openSignInModal();
      }
    })
  }

  useEffect(() => {
    if (emailExisted) {
      props.setErrorReceiveEmail({ field: 'email', message: 'common.your_email_address_exist' })
    } else if (!errorEmail) {
      props.setErrorReceiveEmail(null)
    }

  }, [emailExisted])

  useEffect(() => {
    if (
      errorEmail && (!props.errorReceiveEmail || (props.errorReceiveEmail && !props.errorReceiveEmail.message))
    ) {
      props.setErrorReceiveEmail({ field: 'email', message: 'common.your_email_error' })
    }
  }, [valueEmail])
  useEffect(() => {
    if (registerData.first_name && registerData.last_name && registerData.password) {
      dispatch(setIsSaveCard(true))
    } else {
      dispatch(setIsSaveCard(false))
    }
  }, [registerData])
  //validation form
  useEffect(() => {
    if (props.formData.password.length >= 8 && props.formData.password && props.formData.first_name && props.formData.last_name) {
      if (!props.formData.email) {
        props.setErrorReceiveEmail({ field: 'email', message: 'common.your_email_error' })
      } else if (emailExisted) {
        props.setErrorReceiveEmail({ field: 'email', message: 'common.your_email_address_exist' })
      } else {
        if (errorEmail) {
          if (props.errorReceiveEmail.message === 'common.your_email_format_error') {
            props.setErrorReceiveEmail({ field: 'email', message: 'common.your_email_format_error' })
          } else {
            props.setErrorReceiveEmail({ field: 'email', message: 'common.your_email_error' })
          }
        } else {
          props.setErrorReceiveEmail(null)
        }
      }
    }
    else {
      if (!props.errorReceiveEmail || (props.errorReceiveEmail && props.errorReceiveEmail.field !== 'email')) {
        if (props.formData.password || props.formData.first_name || props.formData.last_name) {
          if (!props.formData.first_name) {
            props.setErrorReceiveEmail({ field: 'first_name', message: 'common.your_first_name_error' })
          } else if (!props.formData.last_name) {
            props.setErrorReceiveEmail({ field: 'last_name', message: 'common.your_last_name_error' })
          } else if (!props.formData.password) {
            props.setErrorReceiveEmail({ field: 'password', message: 'common.your_password_error' })
          } else if (props.formData.password.length < 8) {
            props.setErrorReceiveEmail({ field: 'password', message: 'common.your_password_minlength' })
          } else {
            props.setErrorReceiveEmail(errorEmail ? { field: 'email', message: 'common.your_email_error' } : null)
          }
        } else {
          props.setErrorReceiveEmail(null)
        }
      }
    }
  }, [props.formData])

  return (
    <div id="ReceiveYourConfirmation">
      <Row className={`stripe_card_session custom_stripe_card_session`}>
        <Col xs="12" className="custom_stripe_name_on_card">
          <div className="email-error">
            {(props.payButtonClicked || emailExisted) && props.errorReceiveEmail && props.errorReceiveEmail.field === 'email' && (
              <div>
                <span>
                  <IntlMessage id={props.errorReceiveEmail.message} />
                </span>
                {emailExisted && (
                  <span>
                    Please&nbsp;
                    <span className='login-span' onClick={props.openSignInModal}>
                      Sign-In
                    </span>
                  </span>
                )}
              </div>
            )}
          </div>
          <input
            id="email"
            name="email"
            required
            placeholder={isTablet ? `Enter email address (required)` : `Enter your email address (required)`}
            value={props.formData.email}
            onChange={handleOnChange}
            className="custom_input_stripe"
          />
        </Col>

        <Grid
          item
          xs={12}
          className="align-items-center justify-content-start flex-row d-flex mt-3 mt-md-4 mb-3 checkbox-item"
        >
          <Checkbox
            color="primary"
            id="keep_me"
            name="keep_me"
            checked={props.formData.keep_me}
            onChange={handleOnChange}
            className={'checkBox'}
            icon={<img src={CheckboxIcon} />}
            checkedIcon={<img src={CheckboxCheckedIcon} />}
          />
          <label for="keep_me" className={`detail`}>
            <span className="keep_me_desktop">
              Keep me informed about holidays and special offers
            </span>
            <span className="keep_me_mobile">
              Keep me informed about special offers
            </span>
          </label>
        </Grid>

        <Col xs="12" className="header">
          Track Your Prayer - Sign Up <span>(optional)</span>
        </Col>

        <Col xs="6" className="pr-9px">
          <input
            id="first_name"
            name="first_name"
            placeholder="First Name"
            value={props.formData.first_name}
            onChange={handleOnChange}
            className="custom_input_stripe"
            autoComplete="off"
          />
        </Col>

        <Col xs="6" className="pl-9px">
          <input
            id="last_name"
            name="last_name"
            placeholder="Last Name"
            value={props.formData.last_name}
            onChange={handleOnChange}
            className="custom_input_stripe"
            autoComplete="off"
          />
        </Col>

        <Col xs="12" className="mt-13px">
          <input
            id="password"
            name="password"
            type="password"
            placeholder="Enter Password"
            value={props.formData.password}
            onChange={handleOnChange}
            className="custom_input_stripe"
            autoComplete="new-password"
          />
        </Col>

        {
          isDesktop ?
            <Col xs="12" className="policy">
              By Signing Up you agree to our
              <a href="/terms" target="_blank" className="hippie-blue-link pointer"> Terms </a>
              and
              <a href="/terms" target="_blank" className="hippie-blue-link pointer"> Privacy Policy.</a>
            </Col>
            :
            <Col xs="12" className="policy">
              By Signing Up you agree to our
              <a href="/terms" target="_blank" className="hippie-blue-link pointer"> Terms of Service </a>
              and our
              <a href="/terms" target="_blank" className="hippie-blue-link pointer"> Privacy Policy. </a>
            </Col>

        }

      </Row>
    </div>
  )
};

export default ReceiveYourConfirmation;
